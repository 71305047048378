.client-section {
  font-family: rajdhani;
  margin-top: 60px;
  margin-bottom: 90px;
}

.client-section .header {
  text-align: center;
  padding: 0px 0px 10px 0px;
}

.client-section .header h5 {
  color: #5f70ff;
  font-weight: bold;
  position: relative;
  /* width: 200px; */
  /* display: flex; */
}

.client-section .header h5:before {
  content: "";
  position: absolute;
  left: calc(50% - 140px);
  width: 40px;
  height: 2px;
  background: #5f70ff;
  display: flex;
  align-items: center;
  top: 11px;
}

.client-section .header h5:after {
  content: "";
  position: absolute;
  right: calc(50% - 140px);
  width: 40px;
  height: 2px;
  background: #5f70ff;
  display: flex;
  align-items: center;
  top: 11px;
}

.client-section .header h2 {
  font-size: 40px;
  font-weight: bold;
  margin: 25px 0px;
}

.slider-body {
  align-items: center;
  background: #e3e3e3;
  display: flex;
  justify-content: center;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-350px * 7));
  }
}

.slider {
  background: white;
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
  height: 200px;
  margin: auto;
  align-items: center;
  overflow: hidden;
  position: relative;

  &::before,
  &::after {
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    content: "";
    height: 200px;
    position: absolute;
    width: 150px;
    z-index: 2;
  }

  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  &::before {
    left: 0;
    top: 0;
  }

  .slide-track {
    animation: scroll 30s linear infinite;
    display: flex;
    width: calc(350px * 14);
  }

  .slide {
    height: 150px;
    width: 350px;
  }

  .carousel-image {
    padding-left: 15px;
    padding-right: 15px;
    width: 350px; /* Adjust to your desired width */
    height: 150px; /* Adjust to your desired height */
    object-fit: contain; /* Preserve aspect ratio while covering the specified dimensions */
  }
}
