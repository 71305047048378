.main {
  width: 100%;
  height: auto;
  margin-top: 100px;
}
.smart-accounts-details {
  font-family: rajdhani;
}
.description-mybluebook {
  margin-top: 50px;
}
.description-mybluebook h1 {
  font-weight: bold;
  margin-bottom: 25px;
}

.description-mybluebook p {
  font-size: 22px;
  margin-bottom: 25px;
}
.Breadcrumb {
  background-image: url(../../images/header.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 370px;
  text-align: center;
  display: flex;
  align-items: center;
  color: white;
  font-family: "Rajdhani";
  font-weight: bold;
  justify-content: center;
}
.Breadcrumb h1 {
  font-weight: bold;
}

.Breadcrumb a {
  color: white;
}
.Breadcrumb a:hover {
  color: #0d6efd;
}
.description-mybluebook h4 {
  font-weight: bold;
  margin-bottom: 20px;
}

.description-mybluebook ul li {
  list-style: none;
  font-size: 20px;
}

.description-mybluebook ul {
  padding-left: 0;
}

.description-mybluebook img {
  margin-right: 15px;
}

.header-text-blubook {
  font-size: 3.5rem;
  color: #3b457c;
}
.sub-header-text-blubook {
  font-size: 2.5rem;
  color: #3b457c;
}

.side-border {
  border-right: 1px solid #d3d3d3;
}

.service-header {
  color: #32325c;
}
.section-gap {
  margin-top: 70px;
}


.react-tabs__tab--selected {

  border-color: #0d6efd;

}

.text-blue-color{
  color: #3b457c;
}

.bg-blue-color{
  background-color: #5764a5;
}

.gradient-custom {
  background: radial-gradient(50% 123.47% at 50% 50%, #00ff94 0%, #720059 100%),
    linear-gradient(121.28deg, #669600 0%, #ff0000 100%),
    linear-gradient(360deg, #0029ff 0%, #8fff00 100%),
    radial-gradient(100% 164.72% at 100% 100%, #6100ff 0%, #00ff57 100%),
    radial-gradient(100% 148.07% at 0% 0%, #fff500 0%, #51d500 100%);
  background-blend-mode: screen, color-dodge, overlay, difference, normal;
}
.carousel-indicators {
  border-radius: 50%;
  width: 10px;
  height: 10px;
}
