.error-message{
    height: auto;
    margin-top: 90px;
    color: #3A447C;
    margin-bottom: 50px;
    
}

.err-img{
    margin-top:50px ;
    max-width: 100%;
    height: auto;
}

.go-home-btn{
    background-color: #3A447C;
    border-radius: 50%;
    padding: 5px 15px 5px 15px;
    border: #3A447C 1px;
    
}
.go-home-btn a{
    color: white;
}
@media (max-width:786px) {
    .err-img{
        width: 470px;
        height: 350px;
    }
    
}