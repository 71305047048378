.our-services {
  font-family: rajdhani;
}

.our-services .header {
  text-align: center;
  padding: 0px 0px 10px 0px;
}

.our-services .header h5 {
  color: #5f70ff;
  font-weight: bold;
  position: relative;
  /* width: 200px; */
  /* display: flex; */
}

.our-services .header h5:before {
  content: "";
  position: absolute;
  left: calc(50% - 100px);
  width: 40px;
  height: 2px;
  background: #5f70ff;
  display: flex;
  align-items: center;
  top: 11px;
}

.our-services .header h5:after {
  content: "";
  position: absolute;
  right: calc(50% - 100px);
  width: 40px;
  height: 2px;
  background: #5f70ff;
  display: flex;
  align-items: center;
  top: 11px;
}

.our-services .header h2 {
  font-size: 40px;
  font-weight: bold;
  margin: 25px 0px;
}

.service-box {
  margin-bottom: 40px;
}
.service-items {
  background-image: url(../../images/servicebg.webp);
  padding: 40px 30px 85px 30px;
  box-shadow: none;
  background-size: 108% 109%;
  background-repeat: no-repeat;
  background-position: -13px -13px;
  text-align: center;
  position: relative;
  height: 100%;
}

.service-items h4 {
  color: #151423;
  font-weight: bold;
  font-size: 30px;
  letter-spacing: 1px;
}
.service-details p {
  margin-bottom: 0;
}
.service-title {
  margin-top: 15px;
  color: #151423;
}
.service-items .see-more {
  position: absolute;
  left: calc( 50% - 31px);
  bottom: 10px;
}
.see-more .fa-arrow-right {
  background: #5f70ff;
  padding: 20px;
  border-radius: 50%;
  color: white;
}

@media (min-width: 787px) and (max-width: 1080px) {
  .our-services {
    height: auto;
  }
}

@media (min-width: 1081px) {
  .our-services {
    height: auto;
    padding-bottom: 20px;
  }
}

@media (max-width: 786px) {
  .our-services {
    height: auto;
    padding-bottom: 30px;
  }

  .header {
    padding: 0;
  }

  .services {
    display: block;
    padding: 5%;
    margin-left: 0;
  }

  .service-items {
    margin-bottom: 20px;
    margin-right: 0;
  }

  /* .service-details{
        margin-top: -30px;
    } */
}