.atlas-cms-details {
  font-family: rajdhani;
}
.description1 img {
  max-width: 100%;
}

.description-atlas {
  margin-top: 50px;
}
.description-atlas h1 {
  font-weight: bold;
  margin-bottom: 35px;
  color: #3b457c;
}
.description-atlas h2 {
  color: #3b457c;
  font-weight: bold;
  margin-bottom: 25px;
}
.description-atlas p {
  font-size: 22px;
}

.header-text-atlas-cms {
  font-size: 4rem;
  color: #3b457c;
}
.sub-header-text-atlas-cms {
  font-size: 2.3rem;
 
}

.atlas-cms-details h3 {
  font-weight: bold;
  margin-bottom: 20px;
}

.margin-top-5rem {
  margin-top: 5rem;
}

@media (max-width: 768px) {
  .header-text-atlas-cms {
    font-size: 3rem; /* Adjust the font size as needed */
  }

  .sub-header-text-atlas-cms {
    font-size: 2rem; /* Adjust the font size as needed */
  }
}


