.about-us-section {
  font-family: rajdhani;
  margin-bottom: 80px;
}
.about-us-bg {
  background-image: url(../../images/aboutUsBg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 50vh;
  text-align: center;
  display: flex;
  align-items: center;
  color: white;
  font-family: "Rajdhani";
  font-weight: bold;
  justify-content: center;
  position: relative;

}
.about-us-bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  backdrop-filter: blur(3px);
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.5),
    rgba(82, 93, 196, 0.75)
  ); /* Adjust color and opacity as needed */
}
.about-us-bg span{
    position: relative;
    z-index: 2;
    font-size: 3.5rem;
}
.about-us-title .head-title{
    font-size: 5rem;
    color: #5f70ff;
}
.about-us-title .sub-title{
    font-size: 3.5rem;
}

.about-us-title{
 border-right:1px solid #0d6efd;
}

.about-us-page-gap{
    margin-top: 6rem;
}

.mission-section, .vision-section{
    background: white;
    box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
}

.mission-section , .vision-section {
    font-family: rajdhani;
    margin-top: 60px;
  }
  
  .mission-section .header {

  }
  
  .mission-section .header .header-title {

    color: #5f70ff;
    font-weight: bold;
    position: relative;
    font-size: 2.5rem;
    /* width: 200px; */
    /* display: flex; */
  }

  .vision-section .header .header-title {

    color: #5f70ff;
    font-weight: bold;
    position: relative;
    font-size: 2.5rem;
    /* width: 200px; */
    /* display: flex; */
  }
  
  
  .mission-section .header .header-title:after {
    content: "";
    position: absolute;
    left: 140px;
    width: 60px;
    height: 3px;
    background: #5f70ff;
    display: flex;
    align-items: center;
    top: 25px;
  }
  .vision-section .header .header-title:after {
    content: "";
    position: absolute;
    left: 120px;
    width: 60px;
    height: 3px;
    background: #5f70ff;
    display: flex;
    align-items: center;
    top: 25px;
  }

  .join-us-section h1{
    font-size: 3rem;
}
