.footer-area .container {
    font-family: rajdhani;
    padding-top: 50px;
    border-top: 2px solid rgb(228 228 228);
    margin-top: 30px;
}
.footer-area h4 {
    color: #5f70ff;
    font-weight: 600;
    font-size: 20px;
    position: relative;
    margin-bottom: 20px;
}

.footer-area h4:after {
    position: absolute;
    content: "";
    width: 40px;
    height: 2px;
    background: #5f70ff;
    top: calc(50% - 0px);
    margin-left: 7px;
}
.address{
    display: flex;
    justify-content: space-between;
}

.add-1, .add-2{
    width: 50%;
}
.address li {
    list-style: none;
}
.footer-area h5 {
    font-weight: bold;
    font-size: 18px;
}
.footer-social-icon a {
    font-size: 20px;
    padding-right: 20px;
}

.footer-service li a, .useful-link li a {
    color: #535353;
    padding: 0;
}

.footer-service li a:hover, .useful-link li a:hover {
    color: #5f70ff;
}

.footer-service ul, .useful-link ul{
    padding-left: 20px;
}
.main-footer .container{
    padding-top: 10px;
}
.main-footer .container p{
    margin-bottom: 10px;
}

.footer-social-icon .facebook {
    color: #5f70ff;
  }

  .footer-social-icon .linkedin {
    color: #5f70ff;
  }

  .footer-social-icon .whatsapp {
    color: #5f70ff;
  }
  .footer-social-icon .imoIcon {
    width: 26px;
    height: 26px;
    margin-top: -4px;
  }

@media (max-width: 768px) {
    .footer-area .col-6{
        padding-right: 8px;
    }
    .footer-area .nav-link {
        padding: 0!important;
    }
    .address{
        flex-direction: column;
    }
  
    .add-1, .add-2{
        width: 100%;
    }
    .footer-social h4{
        display: none;
    }
}