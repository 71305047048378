.managed-service-details {
  font-family: rajdhani;
}

.main-section {
  background-color: #dcedfc;
  height: 70vh;
}
.main-section h1 {
  font-size: 45px;
}
.managed-service-details h2 {
  color: #32325c;
}

@media screen and (max-width: 992px) {
  .section-2 {
    margin-top: 30px;
  }
}
@media screen and (max-width: 786px) {
  .main-section h1 {
    font-size: 40px;
  }
  .main-section {
    height: 80vh;
  }
}
@media screen and (max-width: 576px) {
  .image-container {
    gap: 50px;
  }
}
