.about .container {
    padding: 50px 30px;
}

.about img {
    max-width: 100%;
}


.about-content {
    margin-top: 30px;
    font-family: 'Rajdhani';
}

.about-content h3 {
    font-weight: bold;
    color: #5f70ff;
    font-size: 22px;
    position: relative;
    margin-bottom: 30px;
}

.about-content h1 {
    font-weight: bold;
    margin-bottom: 30px;
}

.about-content h3:after {
    content: "";
    position: absolute;
    left: 130px;
    width: 40px;
    height: 2px;
    background: #5f86ff;
    top: 11px;
}

.wehave {
    display: flex;
    justify-content: flex-start;
    color: #5f70ff;
    margin-top: 30px;
    align-items: center;
}

.wehave h1 {
    margin-bottom: 0;
}

.wehave h4 {
    color: #5F70FF;
    font-family: Rajdhani;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0;
}

.expertise-button button {
    width: 150px;
    height: 60px;
    flex-shrink: 0;
    font-weight: bold;
    font-size: 20px;
    border-radius: 100px;
    background: #5f70ff;
    border: none;
    margin-left: 100px;
}

