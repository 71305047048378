.cloud-management-details {
  font-family: rajdhani;
}

.main-section {
  background-color: #dcedfc;
  height: 70vh;
}
.main-section h1 {
  font-size: 45px;
}
.cloud-management-details h2 {
  color: #32325c;
}
.list-icon {
  margin-top: -2rem;
}
.image-container {
  gap: 100px;
}
.icon {
  width: 80px;
  margin-bottom: 10px;
  /* Adjust the margin as needed */
}
.icon-container {
  position: relative;
  margin: 0 30px; /* Adjust the margin as needed */
}

.icon-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px; /* Adjust the height of the bar as needed */
  background-color: #5f70ff; /* Change the color of the bar */
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease;
}
.faq-section{
  margin-top: 50px;
  margin-bottom: 40px;
}
.icon-bar.active {
  transform: translateX(0);
  background-color: #5f70ff; /* Set the background color for the active icon bar */
}
.service-bg {
  background-color: #f6f7f9;
}
.service-bg p {
  color: #32325c;
}
.service-list {
  transition: transform 0.3s ease;
}

.icon-container:hover .icon-bar {
  transform: scaleX(1);
}

@media screen and (max-width: 992px) {
  .section-2 {
    margin-top: 30px;
  }
}
@media screen and (max-width: 786px) {
  .main-section h1 {
    font-size: 40px;
  }
  .main-section {
    height: 90vh;
  }
}
@media screen and (max-width: 576px) {
  .image-container {
    gap: 50px;
  }
}
