.our-expertise-details {
  font-family: rajdhani;
}
.text-color {
  color: #3b457c;
}
.header-text {
  color: white;
  background-color: #5f70ff;
}
.section-4 {
  margin-top: 80px;
}

ul {
  padding-left: 0;
}
li {
  list-style: none;
}
.list-icon {
  margin-top: -0.75rem;
}


.logo-container {
  top: -200px;
  bottom: -200px;
  position: relative;
  margin-bottom: -200px; /* Remove unused space after logo container */
  
}

.Breadcrumb {
  background-image: url(../../images/header.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 370px;
  text-align: center;
  display: flex;
  align-items: center;
  color: white;
  font-family: "Rajdhani";
  font-weight: bold;
  justify-content: center;
}
.Breadcrumb h1 {
  font-weight: bold;
}

.Breadcrumb a {
  color: white;
}
.Breadcrumb a:hover {
  color: #0d6efd;
}
