.topbar {
  background: #3b457c;
}
.topbar ul {
  padding: 10px 0px;
  margin: 0;
  display: flex;
}

.topbar li {
  list-style: none;
  color: white;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  font-size: 14px;
}
.topbar li:not(:first-child) {
  padding: 0px 30px;
}
.topbar li:last-child {
  padding-right: 0;
}
svg.svg-inline--fa {
  padding-right: 5px;
}
.top-right {
  display: flex;
  justify-content: flex-end;
}
.topbar-social-icon {
  padding-left: 10px;
}
.topbar-social-icon a {
  margin-right: 5px;
  color: white;
}
.imologo {
  width: 23px;
  height: 23px;
  margin-top: -4px;
}
@media screen and (max-width: 768px) {
  .desktop {
    display: none;
  }
  .top-right {
    justify-content: center;
  }
}
