.get-start {
  background-color: #3a447c;
}

.bg-myNav {
  background-color: #ffffff;
  padding-top: 0px;
  padding-bottom: 0px;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
}

.nav-link {
  color: #3a447c;
  font-size: 17px;
  font-weight: 500;
  padding: 0.5rem 1rem;
}

.nav-link:hover,
.nav-link:focus {
  color: #0a58ca;
}

.contact.nav-link {
  background: #5f70ff;
  color: white;
  padding: 20px;
}

.navbar-brand img {
  height: 35px;
}

.dropdown-menu {
  border: none; /* Remove the border */
  border-radius: 0; /* Remove rounded corners */
}
.dropdown-menu .dropdown-item {
  color: #3a447c;
  font-size: 15px;
  font-weight: 500;
  padding: 0.5rem 1rem;
}
.dropdown-menu .dropdown-item:hover {
  color: #0a58ca;
}
@media (max-width: 786px) {
  #home {
    position: sticky;
    top: -1px;
    height: 48px;
    z-index: 9999;
  }

  /* Add the "hidden" class to fully hide the header */
  #home.hidden {
    transform: translateY(-100%);
  }

  /* Optional: Add smooth transition for the hiding effect */
  #home {
    transition: transform 0.3s ease;
  }
  .myNav {
    z-index: 1001;
  }

  .navbar {
    border-radius: 5px;
    background: rgba(59, 69, 124, 0.12);
    padding: 4px 0px;
    min-height: 48px;
  }

  .navbar .container {
    flex-direction: row-reverse;
  }

  /* .bg-myNav {
    overflow: visible;
    transform: translate3d(0px, -100%, 0px);
    transition: all 0.3s ease 0s;
  } */

  .navbar-collapse {
    position: absolute;
    width: 90%;
    z-index: 999;
    top: 0px;
    left: 0px;
    height: 100vh;
    background: linear-gradient(180deg, #3b457c 0%, rgba(59, 69, 124, 0) 100%);
    padding-left: 30px;
    padding-top: 65px;
  }

  .nav-link {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    padding: 0.5rem 1rem;
    border-top: 1px solid #fff;
    width: 92%;
  }

  .contact.nav-link {
    background: none;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid #fff;
  }

  .navbar-brand {
    display: flex;
    padding: 0;
    margin: 0;
  }

  .navbar-toggler.collapsed {
    background: url(../../images/menuWhite.png);
    border: 0;
    position: initial;
    display: inline-block;
    width: 24px;
    height: 24px;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    border-radius: 0;
  }

  .navbar.scrolled {
    background: #fff;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }

  /* .navbar.scrolled {
    background: #fff;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    transform: translate3d(0px, 0px, 0px);
    transition: all 0.3s ease 0s;
  } */

  .scrolled .navbar-toggler.collapsed {
    background: url(../../images/menu.png);
    border: 0;
    position: initial;
    display: inline-block;
    width: 24px;
    height: 24px;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    border-radius: 0;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .navbar-toggler-icon {
    display: none;
  }

  .navbar-brand img {
    height: 25px;
  }

  .navbar-toggler {
    background: url(../../images/cross.png);
    border: 0;
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    border-radius: 0;
    z-index: 9999;
    position: absolute;
    left: 35px;
  }

  .header-social-icon .facebook {
    color: #0674e7;
  }

  .header-social-icon .linkedin {
    color: #0077b5;
  }

  .header-social-icon .whatsapp {
    color: #128c7e;
  }
  .header-social-icon .imoIcon {
    width: 23px;
    height: 23px;
    margin-top: -4px;
  }
}
