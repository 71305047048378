.contact-details {
  font-family: rajdhani;
}

.form-bg {
  background-color: #dcedfc;
}
.flag {
  width: 35px;
}
.phone-icon {
  margin-top: -10px;

  margin-right: 10px;
}

.contact-social-icon .facebook {
  color: #0674e7;
}

.contact-social-icon .linkedin {
  color: #0077b5;
}

.contact-social-icon .whatsapp {
  color: #128c7e;
}
.contact-social-icon .imoIcon {
  width: 32px;
  height: 32px;
  margin-top: -15px;
}
a {
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
a,
a:hover {
  text-decoration: none !important;
}

.text-black {
  color: #000;
}

.content {
  padding: 7rem 0;
}

.contact-details h2 {
  font-size: 3.5rem;
  font-weight: 700;
}

.contact-details h3 {
  font-size: 2rem;
  font-weight: 700;
}

.form-control {
  border: none;
  border: 1px solid #d9d9d9;
  border-radius: 0;
}
.form-control:active,
.form-control:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #000;
}

.col-form-label {
  color: #000;
}

.btn,
.form-control {
  height: 55px;
}

.btn:active,
.btn:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

label.error {
  font-size: 12px;
  color: red;
}

#message {
  resize: vertical;
}

#form-message-warning,
#form-message-success {
  display: none;
}

#form-message-warning {
  color: #b90b0b;
}

#form-message-success {
  color: #55a44e;
  font-size: 18px;
  font-weight: bold;
}

.submitting {
  float: left;
  width: 100%;
  padding: 10px 0;
  display: none;
  font-weight: bold;
  font-size: 12px;
  color: #000;
}
