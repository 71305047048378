
@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;700&display=swap');
body{
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  
}
a{
  text-decoration: none!important;
}
selector {
  print-color-adjust: exact;
}
@media (max-width: 786px) {
  .img {
    margin-left: 10px;
    height: 50px;
  }
}

.carousel-indicators {
  bottom: -17px !important;
}
.carousel-indicators [data-bs-target] {
  background-color: #565656 !important;
}
