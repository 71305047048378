.main {
  width: 100%;
  height: auto;
  margin-top: 100px;
}
.smart-accounts-details {
  font-family: rajdhani;
}
.description1 {
  margin-top: 50px;
}
.description1 h1 {
  font-weight: bold;
  margin-bottom: 35px;
}
.description1 h2 {
  color: #3b457c;
  font-weight: bold;
  margin-bottom: 25px;
}
.description1 p {
  font-size: 22px;
  margin-bottom: 25px;
}
.Breadcrumb {
  background-image: url(../../images/header.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 370px;
  text-align: center;
  display: flex;
  align-items: center;
  color: white;
  font-family: "Rajdhani";
  font-weight: bold;
  justify-content: center;
}
.Breadcrumb h1 {
  font-weight: bold;
}

.Breadcrumb a {
  color: white;
}
.Breadcrumb a:hover {
  color: #0d6efd;
}
.sales-features h4 {
  font-weight: bold;
  margin-bottom: 20px;
}

.sales-features ul li {
  list-style: none;
  font-size: 20px;
}

.sales-features ul {
  padding-left: 0;
}

.sales-features img {
  margin-right: 15px;
}
