.main {
    width: 100%;
    height: auto;
    margin-top: 100px;
  }
  .smart-accounts-details {
    font-family: rajdhani;
  }
  .description-mybluebook {
    margin-top: 50px;
  }
  .description-mybluebook h1 {
    font-weight: bold;
    margin-bottom: 25px;
  }

  .description-mybluebook p {
    font-size: 22px;
    margin-bottom: 25px;
  }
  .Breadcrumb {
    background-image: url(../../images/header.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 370px;
    text-align: center;
    display: flex;
    align-items: center;
    color: white;
    font-family: "Rajdhani";
    font-weight: bold;
    justify-content: center;
  }
  .Breadcrumb h1 {
    font-weight: bold;
  }
  
  .Breadcrumb a {
    color: white;
  }
  .Breadcrumb a:hover {
    color: #0d6efd;
  }
  .description-mybluebook h4 {
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .description-mybluebook ul li {
    list-style: none;
    font-size: 20px;
  }
  
  .description-mybluebook ul {
    padding-left: 0;
  }
  
  .description-mybluebook img {
    margin-right: 15px;
  }
  