.product-area {
  font-family: rajdhani;
  text-align: center;
  margin-bottom: 90px;
}

.product-area .header {
  text-align: center;
  padding: 0px 0px 10px 0px;
}

.product-area .header h5 {
  color: #5f70ff;
  font-weight: bold;
  position: relative;
}

.product-area .header h5:before {
  content: "";
  position: absolute;
  left: calc(50% - 110px);
  width: 40px;
  height: 2px;
  background: #5f70ff;
  display: flex;
  align-items: center;
  top: 11px;
}

.product-area .header h5:after {
  content: "";
  position: absolute;
  right: calc(50% - 110px);
  width: 40px;
  height: 2px;
  background: #5f70ff;
  display: flex;
  align-items: center;
  top: 11px;
}

.product-area .header h2 {
  font-size: 40px;
  font-weight: bold;
  margin: 25px 0px;
}
.products-content {
  text-align: center;
  padding: 20px;
  min-height: 200px;
  background: #F4F8FF;
  border-radius: 10px;
  position: relative;
  margin-top: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.products-content .see-more {
  position: absolute;
  bottom: -34px;
  left: calc( 50% - 31px);
}
.products-content h2 {
  font-weight: bold;
  color: #5e6ffd;
}
.products-content p {
  line-height: 20px;
}
@media screen and (min-width: 768px) {
  .products img {
    width: 90%;
  }
  .products-content{
    width: 99%;
  }
}