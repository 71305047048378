.banner img {
    width: 100%;

}
.banner{
    z-index: 1;
}

.banner-overlay .content h4 {
    color: #5F70FF;
    font-size: 30px;
}

.banner-overlay .content h1 {
    color: #3b457c;
    font-weight: bold;
}

.banner-overlay .content h1 span {
    color: #5F70FF;

}

.banner-overlay .content h5 {
    margin: 20px 0px;
    color: black;
    font-weight: 500;
    line-height: 30px;
    font-size: 20px;

}

.btn-contact {
    background: #5f70ff;
    color: white;
    font-size: 18px;
    border-radius: 0;
    padding: 10px 25px;
}

@media (min-width: 768px) {
    .banner {
        position: relative;
        background-image: url(../../images/bannerhome4.jpg);
        background-position: center center;
        background-size: cover;
        width: 100%;
        height: 600px;
        background-repeat: no-repeat;
    }

    .banner-overlay {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(90deg, rgba(255,255,255,0.9) 30%, rgba(95,112,255,0.4) 100%);
        display: flex;
        align-items: center;
        font-family: 'Rajdhani', sans-serif !important;
    }

    .banner-overlay .content {
        width: 45%;
    }

    .banner-overlay .content h1 {
        font-size: 56px;

    }
}

@media (max-width: 768px) {
    .banner {
        position: relative;
        background-image: url(../../images/bannerhome4.jpg);
        background-position: center center;
        background-size: cover;
        width: 100%;
        height: 300px;
        background-repeat: no-repeat;
    }

    .banner-overlay {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(0deg, rgba(255,255,255,0.9) 40%, rgba(95,112,255,0.4) 100%);
        display: flex;
        align-items: center;
        font-family: 'Rajdhani', sans-serif !important;
    }

    .banner-overlay .content h1 {
        font-size: 20px;
        margin-top: 20px;
    }

    .banner-overlay .content h5 {
        font-size: 15px;
        margin: 15px 0px;
        line-height: 25px;
        font-weight: 500;
    }
}